import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/ultra_light/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/ultra_light/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/ultra_light/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/ultra_light/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x405px_Ultra_Light.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/Ultra_Light_960x405px_2016_NEW..jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> Ultra Light
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Ultra lightweight protection</h3>
                      <p>
                        Most sun products provide adequate protection but can
                        compromise on either absorption or skin hydration.
                        <br />
                        With PIZ BUIN<sup>®</sup> ULTRA LIGHT, you can have
                        both!
                        <br />
                        PIZ BUIN<sup>®</sup> ULTRA LIGHT sprays and fluids
                        hydrate your skin just like a moisturising sun lotion,
                        keeping it soft and supple during sun exposure. And
                        thanks to their ultra light non-greasy texture they
                        absorb instantly and invisibly without leaving white
                        marks just like a clear spray.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="dry-touch-sun-fluid" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/77411bad74b349cbf03d128dee4d88d3_f32.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>DRY TOUCH SUN FLUID</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          This sun fluid combines immediate and effective
                          UVA/UVB protection with an ultra light formula that
                          dries instantly. The hydrating fluid spreads easily
                          over your skin for a weightless dry touch feel. The
                          non sticky and non greasy formula absorbs instantly
                          without leaving white marks for skin that feels silky
                          and smooth. Enriched with the anti-oxidant* Vitamin E,
                          it helps to prevent sun induced premature skin ageing.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN<sup>®</sup> ULTRA LIGHT Sun Fluid is sweat
                            and water resistant.
                          </p>
                          <p>
                            PIZ BUIN<sup>®</sup> ULTRA LIGHT Dry Touch Sun
                            Fluids in favor with consumers. We asked consumers
                            to tell us what they thought of PIZ BUIN<sup>®</sup>{" "}
                            ULTRA LIGHT Dry Touch Sun Fluids**.
                          </p>
                          <p>
                            <strong>
                              <span className="orange">85%</span>
                            </strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>An instantly drying texture</li>
                            <li>A non-sticky texture</li>
                          </ul>
                          <p>
                            <strong>
                              <span className="orange">80%</span>
                            </strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>Instant absorption</li>
                            <li>An ultra light texture</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                        <p className="footNote">*In vitro test.</p>
                        <p className="footNote">
                           &nbsp; **Self-assessment results obtained on the SPF 30 Sun
                          Fluid, 20 subjects, 4 weeks at least once a day
                          application.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="dry-touch-face-fluid" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/251215d3258ea6cecf91a6058aa56426_f31.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>DRY TOUCH FACE FLUID</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          This face fluid combines immediate and effective
                          UVA/UVB protection with an ultra light formula that
                          dries instantly. The hydrating fluid spreads easily
                          over your skin for a weightless dry touch feel. The
                          non sticky and non greasy formula absorbs instantly
                          without leaving white marks which makes it ideal to be
                          worn daily. Enriched with the anti-oxidant* Vitamin E
                          derivative, it helps to prevent sun induced premature
                          skin ageing.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN<sup>®</sup> ULTRA LIGHT Face Fluid is sweat
                            and water resistant.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight.
                        </p>
                        <p className="footNote">*In vitro test.</p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="hydrating-sun-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/a9df107eeb496ab99e9a6825f83e7a05_f33.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>HYDRATING SUN SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          With advanced UVA/UVB filters, this sun spray provides
                          immediate and effective sun protection. Enriched with
                          the powerful anti-oxidant* FEVERFEW PFE<sup>™</sup>,
                          it helps to prevent sun induced premature skin ageing.
                          The ultra light, non greasy and non sticky formula
                          absorbs instantly into your skin without leaving white
                          marks. It provides long lasting hydration to keep your
                          skin soft and supply during sun exposure.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN<sup>®</sup> ULTRA LIGHT Hydrating Sun Spray
                            is sweat and water resistant.
                          </p>
                          <p>
                            PIZ BUIN<sup>®</sup> ULTRA LIGHT Hydrating Sun Spray
                            in favour with consumers. We asked consumers to tell
                            us what they thought of PIZ BUIN<sup>®</sup> ULTRA
                            LIGHT Hydrating Sun Spray**.
                          </p>
                          <p>
                            <strong className="orange">100%</strong>{" "}
                            experienced:
                          </p>
                          <ul>
                            <li>Immediate absorption</li>
                            <li>A very light texture</li>
                            <li>A non-greasy texture</li>
                          </ul>
                          <p>
                            <strong className="orange">95%</strong> experienced:
                          </p>
                          <ul>
                            <li>All day long moisturisation</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor10" />
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight. Do not spray directly into face. For use on
                          face, spray into hands and apply. Shake before use.
                        </p>
                        <p className="footNote">*In vitro</p>
                        <p className="footNote">
                           &nbsp; **Self-assessment results obtained on the SPF 30 Sun
                          Spray, 21 subjects, 1 week at least once a day
                          application.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                FEVERFEW PFE<sup>™</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    The Feverfew plants origin is in the
                                    mountain scrub and rocky soil of the Balkan
                                    Peninsula but now it can be found in several
                                    areas of the world. The PIZ BUIN<sup>®</sup>{" "}
                                     parent company, Kenvue, has
                                    patented this innovative extract for use in
                                    skincare, applying a new extraction process
                                    that keeps the active ingredients and
                                    eliminates unwanted components that could
                                    cause allergies.
                                  </p>
                                  <p>
                                    How does FEVERFEW PFE<sup>™</sup> help your
                                    skin?
                                  </p>
                                  <p>
                                    FEVERFEW PFE<sup>™</sup> plant extract is a
                                    highly effective soothing and antioxidant
                                    ingredient. In vitro studies prove that it
                                    has greater antioxidant boosting activity
                                    than other leading extracts including sage,
                                    green tea, black tea, Vitamin C and Vitamin
                                    E. It helps relieve redness of skin, repairs
                                    skin cell damaged by UV rays, and
                                    strengthens skin cell resilience to the
                                    sun*. Its efficacy has been proven by
                                    several years of extensive research,
                                    performed by the PIZ BUIN<sup>®</sup>{" "}
                                    brand’s parent company, Kenvue, with several patents covering its
                                    application in the skin care and cosmetic
                                    fields.
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      Source: Parthenolide-depleted Feverfew
                                      (Tanacetum parthenium) protects skin from
                                      UV irradiation and external aggression,
                                      Arch Dermatol Res (2008) 300:69–80
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 1<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against cell alterations, decreases
                                    UVB-induced erythema and helps repair
                                    UV-damaged cells. Up to 60% improvement on
                                    UVB induced erythema and redness.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue publication
                                      in Arch Dermatol Res Feb 2008; 300 (2)
                                      69-80, Randomised, placebo-controlled,
                                      double blind study on 12 volunteers.
                                    </span>
                                  </p>
                                  <p>
                                    Fig. 2<br />
                                    FEVERFEW PFE<sup>™</sup> helps protect
                                    against sunburn by stimulating natural
                                    processes that help maintain skin cell
                                    integrity* and strengthen their resilience
                                    to the sun.
                                    <br />
                                    <span className="footNote">
                                      Source: Kenvue R&amp;D,
                                      Method: application of UV radiations on
                                      human skin ex plant and assessing the
                                      sunburn cells induced.
                                    </span>
                                  </p>
                                  <p>
                                    <span className="footNote">
                                      *In vitro test
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/39ef095da165482afdc673791b8d83e8_f42.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                GLYCERINE
                                <div className="textBlock bottom">
                                  <p>
                                    Glycerine attracts water and holds it in the
                                    skin to help maintain a good hydration
                                    level.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/617fb948ebbaeb31693807e5a660cfbb_f43.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/en/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/en/our-products/ultra_light/"
                      target="_self"
                    >
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/protect_cool/" target="_self">
                      Protect &amp; Cool
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/wetskin/" target="_self">
                      Wet skin
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/en/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
